import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import AboutPage from "./AboutPage.js";
import PDFButton from "../components/pdf/PDFButton";
import PopUpPDF from "../components/pdf/PopUpPDF";
import TimeBarChartContainer from "../components/charts/TimeBarChartContainer";
import BudgetChartContainer from "../components/charts/BudgetChartContainer.js";
import BusinessDropdown from "../components/BusinessDropdown";
import ClimateVisionText from "../components/ClimateVisionText";
import useData from "../hooks/useData.js";
import useText from "../hooks/useText.js";
import usePartnerCompanyData from "../hooks/usePartnerCompanyData.js";
import useCrossfilter from "../hooks/useCrossfilter.js";
import useDataNational from "../hooks/useDataNational.js";
import { withRouter } from "react-router";
import { useReactToPrint } from "react-to-print";
import MainToggleMenu from "../components/MainToggleMenu";
import RegionView from "./RegionView";
import BusinessView from "./BusinessView";
import NationalView from "./NationalView";
import PDFexporter from "../components/pdf/PDFexporter";
import "./FrontPage.scss";
import useTextFromAPI from "../hooks/useTextFromAPI.js";
import LoadMask from "../components/LoadMask.js";
import SwitchToggle from "../components/SwitchToggle";
import config from "../config/index.js";

import KpLogo from "../static/av-logo_kp.svg";

export function createTextBox(orgnumber, textContent, columnName) {
  const createTextBox = orgnumber
    ? textContent && textContent[columnName]
      ? true
      : false
    : true;
  return createTextBox;
}

const FrontPage = ({ match }) => {
  // console.log("match", match);
  const years = [2024, 2023, 2022, 2021, 2020, 2019];
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  let [titlePage, setTitlePage] = useState(match.url.split("/")[2]);
  // const [groupId, setGroupId] = useState(match.url.substr(1, 1));
  const [groupId, setGroupId] = useState(match.url.split("/")[1]);
  const [includeLargeEmissions, setIncludeLargeEmissions] = useState(true);
  const [orgnumber, setOrgnumber] = useState(match.url.split("/")[3]);
  const [loading, setLoading] = useState(false);
  const [toggleElType, setToggleElType] = useState(2);
  const [currentYear, setCurrentYear] = useState(years[0]);
  const [toggleCheckedFrivillig, setToggleCheckedFrivillig] = useState(true);
  const [toggleCheckedNewPartners, setToggleCheckedNewPartners] =
    useState(true);

  const [didUpdate, setDidUpdate] = useState(false);
  const [PDFType, setPDFType] = useState(undefined);
  const [isReady, setIsReady] = useState(false);
  const pdfComponentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const printContent = useCallback(() => {
    return pdfComponentRef.current;
  }, []);

  const onPrint = useReactToPrint({
    content: printContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: () => setLoading(false),
    onAfterPrint: () => {
      setShowPdfPopup(false);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (groupId !== match.url.substr(1, 1)) {
    if (groupId !== match.url.split("/")[1]) {
      // setGroupId(match.url.substr(1, 1));
      setGroupId(match.url.split("/")[1]);
    }
    if (orgnumber !== match.url.split("/")[3]) {
      setOrgnumber(match.url.split("/")[3]);
    }
    if (titlePage !== match.url.split("/")[2]) {
      setTitlePage(match.url.split("/")[2]);
    }
    //setIsNational(groupId === "0");
  }, [groupId, orgnumber, match, titlePage /**isNational**/]);

  const isNational = useMemo(() => {
    return groupId === "0";
  }, [groupId]);

  const [utslippsTyper, loadingUtslippsTyper] = useData(
    groupId,
    orgnumber,
    null,
    toggleElType,
    currentYear,
    toggleCheckedFrivillig,
    false,
    !toggleCheckedNewPartners,
    !includeLargeEmissions
  );

  const [textContent, loadingTextContent] = useText(
    isNational,
    groupId,
    orgnumber,
    !toggleCheckedNewPartners,
    !includeLargeEmissions,
    null,
    2,
    true,
    currentYear
  );

  const [totalData, loadingTotalData] = usePartnerCompanyData(
    groupId,
    toggleElType,
    currentYear,
    toggleCheckedFrivillig,
    !toggleCheckedNewPartners,
    !includeLargeEmissions,
    showPdfPopup,
  );

  const [dataNational, laodingDataNational] = useDataNational(
    !toggleCheckedNewPartners,
    currentYear,
    toggleElType,
    toggleCheckedFrivillig,
    isNational,
    !includeLargeEmissions
  );

  const [regionText, loadingRegionText] = useTextFromAPI(
    isNational,
    !!orgnumber,
    groupId,
    currentYear
  );

  const loadingContent = useMemo(
    () =>
      loading ||
      loadingRegionText ||
      loadingTextContent ||
      loadingTotalData ||
      loadingUtslippsTyper ||
      laodingDataNational,
    [
      loading,
      loadingRegionText,
      loadingTextContent,
      loadingTotalData,
      loadingUtslippsTyper,
      laodingDataNational,
    ]
  );

  const data = useMemo(() => {
    if (isNational) {
      return dataNational;
    } else {
      return utslippsTyper && utslippsTyper.rows;
    }
  }, [dataNational, isNational, utslippsTyper]);

  const hasData = useMemo(() => {
    return !!textContent;
  }, [textContent]);

  data &&
    data.map((d) => {
      if (
        d.kategori_id === 14 ||
        d.kategori_id === 15 ||
        d.kategori_id === 16 ||
        d.kategori_id === 17
      ) {
        if (!d.kategori_navn.includes("frivillig")) {
          return (d.kategori_navn = d.kategori_navn + " (frivillig)");
        }
      }
      return d;
    });

  const checkIfLargeEmissions = orgnumber
    ? data && data[3] && data[3].utslipp_year2
    : false;

  const dataSorted = useMemo(() => {
    if (!data) return;

    const arr0 = data.map((d, idx) => {
      return {
        idx,
        year: d.year0,
        type: d.kategori_navn,
        value: d.utslipp_year0,
      };
    });

    const arr1 = data.map((d, idx) => {
      return {
        idx,
        year: d.year1,
        type: d.kategori_navn,
        value: d.utslipp_year1,
      };
    });

    const arr2 = data.map((d, idx) => {
      return {
        idx,
        year: d.year2,
        type: d.kategori_navn,
        value: d.utslipp_year2,
      };
    });

    return arr0.concat(arr1).concat(arr2);
  }, [data]);

  const budgetData = useMemo(() => {
    if (!utslippsTyper || !utslippsTyper.budget) return;

    const arr = utslippsTyper.budget.map((d, idx) => {
      return {
        idx,
        year: d.year,
        type: [14, 15, 16, 17].indexOf(d.category_id) !== -1 && !d.category_name.includes("frivillig")
          ? d.category_name = d.category_name + " (frivillig)"
          : d.category_name,
        value: d.emissions,
      };
    });

    return arr;
  }, [utslippsTyper]);

  // Crossfilters :

  const activeCrossfilter = useCrossfilter(dataSorted);
  const pieChartactiveCf = useCrossfilter(data);
  const budgetCrossfilter = useCrossfilter(budgetData);

  // Textboxes :

  const aboutPartnerTitle = orgnumber
    ? ["Om virksomheten"]
    : isNational
    ? ["Om Klimapartnere"]
    : ["Om regionen"];

  const nullUtslippScope1 = orgnumber
    ? data && data[0] && data[0].antall_scope_0_year2 === 1
      ? true
      : false
    : undefined;

  const carbonNeutral = textContent ? textContent.klimakvoter : null;

  // titlePage = orgnumber ? titlePage.split(",")[1] : titlePage;

  const createOmVirksomheten = createTextBox(
    orgnumber,
    textContent,
    "omvirksomheten"
  );

  const renderYearDropdown = (years, currentYear) => {
    return (
      <div className="current-year-selector">
        <span>Vis tall fra </span>
        <select
          value={currentYear}
          onChange={(e) => setCurrentYear(e.target.value)}
        >
          {years.map((y) => {
            return (
              <option key={y} value={y}>
                {y}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  return (
    <div className="front-page-container">
      {loadingContent && !data && !totalData && groupId !== "-" ? (
        <LoadMask loading={loadingContent} />
      ) : (
        <>
          <div style={{ height: 0, overflow: "hidden" }}>
            {totalData && data && showPdfPopup && (
              <PDFexporter
                titlePage={titlePage}
                PDFType={PDFType}
                orgnumber={orgnumber}
                groupId={groupId}
                ref={pdfComponentRef}
                setIsReady={setIsReady}
                setPDFType={setPDFType}
                data={data}
                totalData={totalData}
                budgetData={budgetData}
                textContent={textContent}
                showPdfPopup={showPdfPopup}
                toggleCheckedFrivillig={toggleCheckedFrivillig}
                setDidUpdate={setDidUpdate}
                currentYear={currentYear}
              />
            )}
          </div>
          {titlePage !== "klimaregnskap" && (
            <>
              <div className="title-container">
                <div className="header">
                  <span>
                    <h2 className="main-title">
                      {isNational ? "Klimapartnere" : titlePage}
                    </h2>
                  </span>
                  <span className="push-right">
                    <p>
                      Data fra{" "}
                      <a
                        href="https://www.klimakost.no"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Klimakost
                      </a>
                    </p>
                    <a
                      href="https://www.asplanviak.no"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="av-logo"
                    >
                      <img src={KpLogo} alt="" />
                    </a>
                  </span>
                </div>

                <div className="content-option">
                  <div className="left-selection">
                    {renderYearDropdown(years, currentYear)}
                    <SwitchToggle
                      value1={2}
                      label1={"Lokasjonsbasert"}
                      title1={config.legend.lokasjonsbasert.description}
                      value2={1}
                      label2={"Markedsbasert"}
                      title2={config.legend.markedsbasert.description}
                      onChange={(checked) => setToggleElType(checked)}
                      value={toggleElType}
                      // className="main-menu"
                      className=""
                    />
                  </div>
                  <MainToggleMenu
                    setToggleElType={setToggleElType}
                    setToggleCheckedFrivillig={setToggleCheckedFrivillig}
                    toggleCheckedFrivillig={toggleCheckedFrivillig}
                    setToggleCheckedNewPartners={setToggleCheckedNewPartners}
                    toggleCheckedNewPartners={toggleCheckedNewPartners}
                    toggleElType={toggleElType}
                    groupId={groupId}
                    orgnumber={orgnumber}
                    titlePage={titlePage}
                    includeLargeEmissions={includeLargeEmissions}
                    setIncludeLargeEmissions={setIncludeLargeEmissions}
                    checkIfLargeEmissions={checkIfLargeEmissions}
                  />
                </div>
              </div>
              {!isNational && (
                <BusinessDropdown
                  groupId={groupId}
                  titlePage={titlePage}
                  orgnumber={orgnumber}
                  year={currentYear}
                />
              )}
              {!hasData && !loadingTextContent && (
                <div className="no-data">
                  Ingen data for dette året!
                </div>
              )}
            </>
          )}
          {titlePage !== "klimaregnskap" &&
            (createOmVirksomheten || nullUtslippScope1) && (
              <ClimateVisionText
                className="about"
                textContent={textContent}
                title={aboutPartnerTitle}
                regionText={regionText?.beskrivelse}
                totalCompaniesInRegion={
                  data && data.length !== 0
                    ? data[0].antall_bedrifter_year2
                    : ""
                }
                totalCompaniesScope0={
                  data && data.length !== 0
                    ? toggleCheckedFrivillig
                      ? data[0].antall_scope_0_year2
                      : data[0].antall_scope_0_uf_year2
                    : ""
                }
                orgnumber={orgnumber}
                rowName={orgnumber ? ["omvirksomheten"] : undefined}
                fossilFree={nullUtslippScope1}
                carbonNeutralQuotas={carbonNeutral ? carbonNeutral : 0}
              />
            )}
          {(titlePage === "klimaregnskap" || groupId === null) && <AboutPage />}
          {!orgnumber && !isNational && titlePage !== "klimaregnskap" && (
            <RegionView
              groupId={groupId}
              toggleCheckedFrivillig={toggleCheckedFrivillig}
              textContent={textContent}
              data={data}
              pieChartactiveCf={pieChartactiveCf}
              currentYear={currentYear}
            />
          )}
          {orgnumber && hasData && (
            <BusinessView
              orgnumber={orgnumber}
              toggleCheckedFrivillig={toggleCheckedFrivillig}
              textContent={textContent}
              data={data}
              pieChartactiveCf={pieChartactiveCf}
              currentYear={currentYear}
            />
          )}

          {isNational && (
            <NationalView
              groupId={groupId}
              toggleCheckedFrivillig={toggleCheckedFrivillig}
              textContent={textContent}
              data={data}
              pieChartactiveCf={pieChartactiveCf}
              currentYear={currentYear}
            />
          )}
          {hasData && (
            <TimeBarChartContainer
              activeCrossfilter={activeCrossfilter}
              //isNational={isNational}
              isKlimaRegnskap={titlePage === "klimaregnskap"}
            />
          )}
          {orgnumber && budgetData && budgetData.length > 0 && (
            <BudgetChartContainer
              activeCrossfilter={budgetCrossfilter}
              //isNational={isNational}
              isKlimaRegnskap={titlePage === "klimaregnskap"}
            />
          )}
          {orgnumber && utslippsTyper && utslippsTyper.attachments && utslippsTyper.attachments.length > 0 && (
            <ClimateVisionText
              title="Vedlegg"
              attachments={utslippsTyper.attachments}
            />
          )}
          {/** TimeBarchartContainer is always on the bottom. Its also super-buggy */}

          <div>
            {showPdfPopup && (
              <PopUpPDF
                titlePage={titlePage}
                loading={loading}
                setLoading={setLoading}
                orgnumber={orgnumber}
                groupId={groupId}
                toggleElType={toggleElType}
                toggleCheckedFrivillig={toggleCheckedFrivillig}
                data={data}
                totalData={totalData}
                textContent={textContent}
                showPdfPopup={() => setShowPdfPopup(!showPdfPopup)}
                PDFType={PDFType}
                setPDFType={setPDFType}
                isReady={isReady}
                onPrint={onPrint}
                setDidUpdate={setDidUpdate}
                didUpdate={didUpdate}
              />
            )}

            {titlePage && titlePage !== "klimaregnskap" && !isNational && hasData && (
              <div className="pdf-btn-container">
                <PDFButton showPdfPopup={() => setShowPdfPopup(!showPdfPopup)}>
                  Hent PDF
                </PDFButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(FrontPage);
